import React, { ReactNode, FC } from 'react'

interface Props {
  breakLabel?: string | ReactNode
  breakClassName?: string
  breakLinkClassName?: string
  breakHandler: (event: React.KeyboardEvent<HTMLAnchorElement>) => void
}

const Break: FC<Props> = ({
  breakLabel,
  breakClassName = 'break',
  breakLinkClassName,
  breakHandler,
}) => {
  return (
    <li className={breakClassName}>
      <a
        className={breakLinkClassName}
        role="button"
        tabIndex={0}
        onKeyPress={breakHandler}
      >
        {breakLabel}
      </a>
    </li>
  )
}

export default Break
