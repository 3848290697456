import React, { FC } from 'react'

interface Props {
  pageSelectedHandler: (event: React.KeyboardEvent<HTMLAnchorElement>) => void
  selected: boolean
  pageClassName?: string
  pageLinkClassName?: string
  activeClassName?: string
  activeLinkClassName?: string
  extraAriaContext?: string
  href?: string
  ariaLabel?: string
  page: number
}

const PageView: FC<Props> = (props) => {
  let { pageClassName, pageLinkClassName } = props
  const {
    page,
    selected,
    activeClassName,
    activeLinkClassName,
    pageSelectedHandler,
    href,
    extraAriaContext,
  } = props

  let ariaLabel =
    props.ariaLabel ||
    'Page ' + page + (extraAriaContext ? ' ' + extraAriaContext : '')
  let ariaCurrent: React.AriaAttributes['aria-current'] = false

  if (selected) {
    ariaCurrent = 'page'

    ariaLabel = props.ariaLabel || 'Page ' + page + ' is your current page'

    if (typeof pageClassName !== 'undefined') {
      pageClassName = pageClassName + ' ' + activeClassName
    } else {
      pageClassName = activeClassName
    }

    if (typeof pageLinkClassName !== 'undefined') {
      if (typeof activeLinkClassName !== 'undefined') {
        pageLinkClassName = pageLinkClassName + ' ' + activeLinkClassName
      }
    } else {
      pageLinkClassName = activeLinkClassName
    }
  }

  return (
    <li className={pageClassName}>
      <a
        role="button"
        className={pageLinkClassName}
        href={href}
        tabIndex={0}
        aria-label={ariaLabel}
        aria-current={ariaCurrent}
        onKeyPress={pageSelectedHandler}
      >
        {page}
      </a>
    </li>
  )
}

export default PageView
