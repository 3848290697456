import React from 'react'
import { graphql, navigate, PageRendererProps } from 'gatsby'
import { map, get } from 'lodash/fp'

// Components
import Layout from 'components/layout'
import { Blocks } from 'components/Blocks'
import SEO from 'components/seo'
import { BlogPosts, BlogPost } from 'components/BlogPosts'
import Pagination from 'components/Pagination'

// Styling
import './pagination.scss'

// Types
import { ContentfulBlogPost, ContentfulPage } from 'types/contentful'

// Types
type PageContext = {
  baseSlug: string
  slug: string
  limit: number
  skip: number
  totalPages: number
  currentPage: number
}

type BlogPostEdge = {
  node: Partial<ContentfulBlogPost>
}

interface Props extends PageRendererProps {
  data: {
    page: ContentfulPage
    blogPosts: {
      edges: BlogPostEdge[]
    }
    site: {
      siteMetadata: {
        siteUrl: string
      }
    }
  }
  pageContext: PageContext
}

type SEOLink = {
  rel: string
  href: string
}

const BlogIndexTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const { page, blogPosts } = data
  const { currentPage, totalPages, baseSlug } = pageContext

  const createSEOLinks = ({
    currentPage,
    baseSlug,
    totalPages,
  }: PageContext): SEOLink[] => {
    const links = []
    const prevPageExists = currentPage - 1 < totalPages && currentPage - 1 > 0
    const nextPageExists = currentPage + 1 < totalPages
    const isCurrentIndex = currentPage === 1

    const prevPageHref = `${data.site.siteMetadata.siteUrl}/${baseSlug}/${
      currentPage - 1
    }`
    const nextPageHref = `${data.site.siteMetadata.siteUrl}/${baseSlug}/${
      currentPage + 1
    }`
    const currentPageHref = isCurrentIndex
      ? `${data.site.siteMetadata.siteUrl}/${baseSlug}`
      : `${data.site.siteMetadata.siteUrl}/${baseSlug}/${currentPage}`

    if (nextPageExists) {
      links.push({ rel: 'next', href: nextPageHref })
    }

    if (prevPageExists) {
      links.push({ rel: 'prev', href: prevPageHref })
    }

    links.push({ rel: 'canonical', href: currentPageHref })

    return links
  }

  const handleOnPageChange = ({ selected }: { selected: number }): void => {
    const pageIndex = selected + 1
    const to = pageIndex === 1 ? `/${baseSlug}` : `/${baseSlug}/${pageIndex}`
    navigate(to)
  }

  const createHrefLinks = (pageIndex: number): string => {
    if (pageIndex === 1) {
      return `/${baseSlug}`
    }
    return `/${baseSlug}/${pageIndex}`
  }

  const theme = page.theme ? page.theme : 'Dark'
  const seoLinks = createSEOLinks(pageContext)
  const seoTitle = page.seoTitle ? page.seoTitle : page.title
  const pageTitle =
    currentPage === 1 ? seoTitle : `${seoTitle} | Sida ${currentPage}`
  const pageExcerpt = get('page.excerpt.excerpt', data)

  return (
    <Layout theme={theme}>
      <SEO title={pageTitle} description={pageExcerpt} links={seoLinks} />
      <Blocks blocks={page.blocks} />
      <BlogPosts>
        {map(
          ({ node }) => (
            <BlogPost
              key={node.slug}
              prefixUrl={baseSlug}
              post={node as ContentfulBlogPost}
              mediaType="fluid"
            />
          ),
          blogPosts.edges
        )}
      </BlogPosts>
      <div className="pagination">
        <Pagination
          nextLabel={
            <>
              <span className="pagination__label pagination__label--next pagination__label--symbol">
                {'»'}
              </span>
              <span className="pagination__label pagination__label--next pagination__label--text">
                {'Nästa'}
              </span>
            </>
          }
          previousLabel={
            <>
              <span className="pagination__label pagination__label--prev pagination__label--symbol">
                {'«'}
              </span>
              <span className="pagination__label pagination__label--prev pagination__label--text">
                {'Föregående'}
              </span>
            </>
          }
          containerClassName="pagination__list"
          pageClassName="pagination__list-item"
          pageLinkClassName="pagination__list-link"
          activeClassName="pagination__active"
          initialPage={currentPage === 1 ? undefined : currentPage - 1}
          pageCount={totalPages}
          pageRangeDisplayed={4}
          marginPagesDisplayed={2}
          onPageChange={handleOnPageChange}
          hrefBuilder={createHrefLinks}
        />
      </div>
    </Layout>
  )
}

export default BlogIndexTemplate

export const query = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!, $baseSlug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    page: contentfulPage(slug: { eq: $baseSlug }) {
      title
      seoTitle
      theme
      excerpt {
        excerpt
      }
      blocks {
        ... on ContentfulBlock {
          ...BlocksFragment
        }
        ... on ContentfulBlockLatestBlogPosts {
          __typename
          id
          title
          category
        }
      }
    }

    blogPosts: allContentfulBlogPost(
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          updatedAt
          createdAt
          publishedAt
          featuredMedia {
            gatsbyImageData(placeholder: BLURRED, quality: 80)
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`
